import React, { useState, useEffect } from 'react';
import {
  View,
  Button,
  Text,
  StyleSheet,
  Dimensions,
} from 'react-native';
import {
  TextInput as PaperTextInput,
  Provider as PaperProvider,
  Button as PaperButton,
} from 'react-native-paper';

const CatFeedingApp = () => {
  const [catWeight, setCatWeight] = useState('');
  const [feedingFrequency, setFeedingFrequency] = useState('');
  const [firstFeedingTime, setFirstFeedingTime] = useState('');
  const [lastFeedingTime, setLastFeedingTime] = useState('');
  const [feedingTimes, setFeedingTimes] = useState([]);
  const [feedingAmount, setFeedingAmount] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [isCalculated, setIsCalculated] = useState(false); // Track if calculation is performed
  const [customAmount, setCustomAmount] = useState('');

  const feedingData = {
    '2.0': 100,
    '2.5': 125,
    '3.0': 150,
    '3.5': 175,
    '4.0': 200,
    '4.5': 225,
    '5.0': 250,
    '5.5': 275,
    '6.0': 300
  };

  useEffect(() => {
    loadCachedData();
  }, []);

  const loadCachedData = () => {
    const cachedData = localStorage.getItem('catFeedingAppData');
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      setCatWeight(parsedData.catWeight || '');
      setFeedingFrequency(parsedData.feedingFrequency || '');
      setFirstFeedingTime(parsedData.firstFeedingTime || '');
      setLastFeedingTime(parsedData.lastFeedingTime || '');
      setCustomAmount(parsedData.customAmount || '');
    }
  };

  const saveDataToCache = () => {
    const data = {
      catWeight,
      feedingFrequency,
      firstFeedingTime,
      lastFeedingTime,
      customAmount,
    };
    localStorage.setItem('catFeedingAppData', JSON.stringify(data));
  };

  const calculateFeedingTimes = () => {
    setIsCalculated(true); // Set flag to indicate calculation is performed
    const weight = parseFloat(catWeight.replace(',', '.')); // Parse weight as a floating-point number
    const weightKeys = Object.keys(feedingData).map(key => parseFloat(key));

    let closestWeight = weightKeys.reduce((a, b) => {
      return Math.abs(b - weight) < Math.abs(a - weight) ? b : a;
    });

    // Round down the weight to the nearest X or X.5
    closestWeight = Math.floor(closestWeight * 2) / 2;

    const portions = parseInt(feedingFrequency);
    let totalAmount;

    if (customAmount !== '') {
      totalAmount = parseFloat(customAmount);
    } else {
      totalAmount = feedingData[closestWeight.toFixed(1)]; // Use toFixed(1) for one decimal place
    }

    setTotalAmount(totalAmount);

    // Handle cases where the weight is not in the feedingData
    const gramsPerPortion = totalAmount !== undefined ? totalAmount / portions : 0;

    const firstTimeParts = firstFeedingTime.split(':');
    const lastTimeParts = lastFeedingTime.split(':');

    const firstHour = parseInt(firstTimeParts[0]);
    const lastHour = parseInt(lastTimeParts[0]);

    const firstTime = firstHour * 60 + (firstTimeParts[1] ? parseInt(firstTimeParts[1]) : 0);
    const lastTime = lastHour * 60 + (lastTimeParts[1] ? parseInt(lastTimeParts[1]) : 0);

    const timeDiff = (lastTime - firstTime) / (portions - 1); // Calculate time difference between feeding times

    const times = [];
    for (let i = 0; i < portions; i++) {
      const minutes = Math.round(firstTime + i * timeDiff);
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      const formattedTime = `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
      times.push(formattedTime);
    }

    setFeedingTimes(times);
    setFeedingAmount(gramsPerPortion.toFixed(2));

    saveDataToCache();

  };

  const windowWidth = Dimensions.get('window').width;
  const inputWidth = windowWidth * 0.8;

  return (
    <PaperProvider>
      <View style={styles.container}>
        <Text style={styles.title}>😺 Cat Feeding App 😼</Text>

        {/* User inputs */}
        <View style={[styles.weightOrCustomWrapper, { width: inputWidth }]}>
          <PaperTextInput
            label="Cat weight in KG"
            keyboardType='decimal-pad'
            value={catWeight}
            onChangeText={text => setCatWeight(text)}
            style={[styles.input, { width: inputWidth / 2.01 }]}
            theme={{ colors: { primary: '#fff' } }}
          />
          <PaperTextInput
            label="Custom Daily dosage (optional)"
            keyboardType='decimal-pad'
            value={customAmount}
            onChangeText={text => setCustomAmount(text)}
            style={[styles.input, { width: inputWidth / 2.01 }]}
            theme={{ colors: { primary: '#fff' } }}
          />
        </View>
        <PaperTextInput
          label="Portions per day"
          keyboardType='numeric'
          value={feedingFrequency}
          onChangeText={text => setFeedingFrequency(text)}
          style={[styles.input, { width: inputWidth }]}
          theme={{ colors: { primary: '#fff' } }}
        />
        <PaperTextInput
          label="First Meal (hh:mm)"
          keyboardType='numbers-and-punctuation'
          value={firstFeedingTime}
          onChangeText={text => setFirstFeedingTime(text)}
          style={[styles.input, { width: inputWidth }]}
          theme={{ colors: { primary: '#fff' } }}
        />
        <PaperTextInput
          label="Last Meal (hh:mm)"
          keyboardType='numbers-and-punctuation'
          value={lastFeedingTime}
          onChangeText={text => setLastFeedingTime(text)}
          style={[styles.input, { width: inputWidth }]}
          theme={{ colors: { primary: '#fff' } }}
        />
        {/* END user inputs */}

        {/* Calculate input according to input */}
        <PaperButton
          mode="contained-tonal"
          buttonColor='rgb(73, 69, 79)'
          labelStyle={{ color: 'rgba(202, 196, 208, 1)', fontWeight: 'bold' }}
          onPress={calculateFeedingTimes}
          theme={{ roundness: 1 }}
        >
          - CALCULATE -
        </PaperButton>

        {/* Display Feeding Times and Amount of grams per portion after calculation */}
        {isCalculated && (
          <View>
            <Text style={styles.subTitle}>Feeding times</Text>
            {feedingTimes.map((time, index) => (
              <Text key={index} style={styles.feedingTime}>{time}</Text>
            ))}
            <Text style={styles.subTitle}>Portion size:
              <Text style={styles.feedingAmount}> {feedingAmount}</Text> grams
            </Text>
            <Text style={styles.subTitle}>Daily requirement:
              <Text style={styles.feedingAmount}> {totalAmount}</Text> grams
            </Text>
          </View>
        )}
      </View>
    </PaperProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    backgroundColor: '#222',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 24,
    color: 'rgba(202, 196, 208, 1)',
  },
  subTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 16,
    color: 'rgba(202, 196, 208, 1)',
    textAlign: 'center'
  },
  input: {
    height: 40,
    borderColor: '#333',
    borderWidth: 1,
    marginBottom: 16,
    paddingHorizontal: 8,
    paddingVertical: 3,
    color: '#fff',
  },
  feedingTime: {
    fontSize: 16,
    marginTop: 8,
    color: 'lightgreen',
    textAlign: 'center'
  },
  feedingAmount: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 8,
    color: 'lightgreen',
    textAlign: 'center'
  },
  weightOrCustomWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

export default CatFeedingApp;
